import { gql } from '@apollo/client';
import { Link, TextField } from '@tackle-io/platform-ui';
import { useVendorListPageQuery } from 'generated/graphql';
import { Magnify } from 'mdi-material-ui';
import CreateVendorModalButton from 'pages/Vendor/tabs/VendorListings/components/CreateVendorModal/CreateVendorModalButton';
import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  Avatar,
  Box,
  Card,
  Grid,
  makeStyles,
  Skeleton,
  Typography,
} from 'vendor/material';

import { EnvironmentTag } from './Vendor/components/EnvironmentTag';
import {
  themeColors,
  VendorEnvironmentEnum,
} from './Vendor/VendorEnvironment.hooks';

const useStyles = makeStyles((theme) => ({
  img: { objectFit: 'contain' },
  card: { height: '100%' },
  lightText: {
    color: themeColors.NEUTRAL000,
  },
  darkText: {
    color: themeColors.TEAL900,
  },
}));

gql`
  query VendorListPage {
    # When Tackle has many more we can invest more time
    vendors(pageSize: 2000) {
      vendors {
        id
        name
        logo_url
        vendor_type
      }
    }
  }
`;

const vendorListPageQueryKey = 'vendors({"pageSize":2000})';
export const addVendorListPageDataToCache = (
  data: any,
  mappedData: any,
): void => {
  const vendorListPageQueryCache = data?.ROOT_QUERY?.[vendorListPageQueryKey];
  if (!vendorListPageQueryCache) return mappedData;
  if (!mappedData.ROOT_QUERY) {
    mappedData.ROOT_QUERY = { __typename: 'Query' };
  }
  mappedData.ROOT_QUERY[vendorListPageQueryKey] = vendorListPageQueryCache;
  vendorListPageQueryCache?.vendors.forEach((vendorRef: { __ref: string }) => {
    const vendorCacheKey = vendorRef.__ref;
    const vendor = data[vendorCacheKey];
    if (!mappedData[vendorCacheKey]) {
      mappedData[vendorCacheKey] = {};
    }
    mappedData[vendorCacheKey].__typename = vendor.__typename;
    mappedData[vendorCacheKey].id = vendor.id;
    mappedData[vendorCacheKey].name = vendor.name;
    mappedData[vendorCacheKey].logo_url = vendor.logo_url;
    mappedData[vendorCacheKey].vendor_type = vendor.vendor_type;
  });
};

const normalize = (s: string | null | undefined) =>
  s?.toLowerCase()?.trim()?.replaceAll(' ', '') || '';

const VendorCard = ({
  id,
  name,
  logo_url,
  vendor_type,
}: {
  id: string;
  name: string;
  logo_url: string | null | undefined;
  vendor_type: string | null | undefined;
}) => {
  const classes = useStyles();
  return (
    <Grid item xs={12} sm={6} md={6} lg={4}>
      <Link to={`/vendor/${id}`} disableStyles>
        <Card style={{ display: 'flex', width: 382 }} className={classes.card}>
          <Box
            style={{ display: 'flex', padding: '24px 0 24px 24px' }}
            width={282}
          >
            <Grid container spacing={2} alignItems="center" wrap="nowrap">
              {logo_url && (
                <Grid item>
                  <Avatar
                    classes={{ img: classes.img }}
                    alt={name}
                    src={logo_url}
                  />
                </Grid>
              )}
              <Grid item xs container direction="column">
                <Grid item>
                  <Typography variant="h6">{name}</Typography>
                </Grid>
                <Grid item>
                  <Typography variant="caption">Vendor ID: {id}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          {vendor_type && (
            <Box
              style={{
                display: 'flex',
                justifyContent: 'left',
                alignItems: 'center',
              }}
              width={100}
            >
              <EnvironmentTag environment={vendor_type} />
            </Box>
          )}
        </Card>
      </Link>
    </Grid>
  );
};

const VendorCardSkeleton = () => (
  <Grid item xs={12} sm={6} md={6} lg={4}>
    <Card>
      <Box p={3}>
        <Grid container spacing={2} alignItems="center" wrap="nowrap">
          <Grid item>
            <Skeleton variant="circle" width="40px" height="40px" />
          </Grid>
          <Grid item xs container direction="column">
            <Grid item>
              <Typography variant="h6">
                <Skeleton width="185px" height="27px" />
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="caption">
                <Skeleton width="135px" height="21px" />
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Card>
  </Grid>
);

const SEARCH_QUERY_PARAM = 'q';

// Helper function to normalize vendor names
export const normalizeName = (name: string | null | undefined) =>
  name?.trim().toLowerCase() ?? '';

const VendorListPage: React.FC = () => {
  const location = useLocation();
  const history = useHistory();
  const queryParams = new URLSearchParams(location.search);
  const searchQuery = queryParams.get(SEARCH_QUERY_PARAM) || '';
  const [shouldPushHistory, setShouldPushHistory] = useState(false);

  const { data, loading } = useVendorListPageQuery({
    fetchPolicy: 'cache-and-network',
  });

  // Prepare the list of vendors for the CreateVendorModalButton component
  const vendors = (data?.vendors?.vendors ?? [])
    .filter((vendor) => vendor.name)
    .map((vendor) => ({ name: normalizeName(vendor.name) }));

  const s = normalize(searchQuery);

  const filteredVendors =
    data?.vendors?.vendors?.filter(
      (v) =>
        (normalize(v.id).includes(s) || normalize(v.name).includes(s)) &&
        v.vendor_type !== VendorEnvironmentEnum.SANDBOX,
    ) || [];

  return (
    <Box p={2}>
      <Grid container direction="column" spacing={2}>
        <Grid
          item
          container
          spacing={2}
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <TextField
              id="search"
              name="search"
              type="search"
              iconLeft={<Magnify />}
              placeholder="search"
              value={searchQuery}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                const newSearchQuery = e.target.value;
                if (!newSearchQuery) {
                  queryParams.delete(SEARCH_QUERY_PARAM);
                } else {
                  queryParams.set(SEARCH_QUERY_PARAM, newSearchQuery);
                }
                if (!shouldPushHistory) {
                  // only use push the first time on this page so we build a history that makes sense
                  setShouldPushHistory(true);
                  history.push({ search: queryParams.toString() });
                } else {
                  // replace here so we dont end up with every keystroke in history
                  history.replace({ search: queryParams.toString() });
                }
              }}
            />
          </Grid>
          <Grid item>
            <CreateVendorModalButton vendors={vendors} />
          </Grid>
        </Grid>
        <Grid item container spacing={3} alignItems="stretch">
          {data ? (
            filteredVendors?.length ? (
              filteredVendors.map((v) => (
                <VendorCard
                  key={v.id}
                  id={v.id!}
                  name={v.name!}
                  logo_url={v.logo_url}
                  vendor_type={v.vendor_type}
                />
              ))
            ) : (
              <Grid item>
                <Typography>No results</Typography>
              </Grid>
            )
          ) : (
            loading && (
              <>
                <VendorCardSkeleton />
                <VendorCardSkeleton />
                <VendorCardSkeleton />
                <VendorCardSkeleton />
                <VendorCardSkeleton />
                <VendorCardSkeleton />
                <VendorCardSkeleton />
                <VendorCardSkeleton />
                <VendorCardSkeleton />
                <VendorCardSkeleton />
                <VendorCardSkeleton />
                <VendorCardSkeleton />
                <VendorCardSkeleton />
                <VendorCardSkeleton />
                <VendorCardSkeleton />
              </>
            )
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default VendorListPage;
